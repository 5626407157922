import React, { useEffect, useState } from "react";
import HeadingMain from "../../../assets/elements/HeadingMain";
import Back_Arrow from "../../../assets/images/icons/arrow-left.svg";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import MainServices from "../../../services/MainServices";
import ReactQuill from "react-quill";
import PageLoader from "../../../assets/loaders/PageLoading";
import axios from "axios";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import { Button } from "react-bootstrap";
import "./passpolicies.scss";
import { PrimaryInput } from "../../../assets/FormElements";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import CustomDigitalSignature from "../../../assets/modal/CustomDigitalSignature";
import {  Modal } from "react-bootstrap";

import Select from "react-select";
import useFeatureSetting from "../../../hooks/useFeatureSetting";

const Policies = () => {
  const { getUserSetting, updatePolicy ,} = MainServices();
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
 const [digitalSignatureModal, setDigitalSignatureModal] = useState(false);
const [selectedBranch, setSelectedBranch] = useState({});
   
 
  const navigate = useNavigate();
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const [settings, setSetting] = useState("");

  //url Params

  //apis
  useEffect(() => {
    type && getSetting();
    //eslint-disable-next-line
  }, []);

  const getSetting = async () => {
    setPageLoading(true);
    const details = {};
    await getUserSetting(details, type)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let settings = resData.data ? resData.data : {};
            setSetting(settings);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePolicyData = async (values) => {
    setButtonLoading(true);
    const details = {
      setting: {
        userIdLenght: values?.userIdLenght,
        appIdelTimeout: values?.appIdelTimeout,
        passwordComplexity: values?.passwordComplexity,
        passwordExpiration: values?.passwordExpiration,
        passwordLength: values?.passwordLength,
        retainPasswordHistory: values?.retainPasswordHistory,
        numberOfLoginAttempts: values?.numberOfLoginAttempts,
        // ...(values?.remark && { remark: values?.remark }),
      },
      ...(values?.password && { password: values?.password }),
    };
    await axios
      .all([
        updatePolicy(details, settings?.publicId, settings?.concurrencyStamp),
      ])

      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            navigate("/password-policies");
            setDigitalSignatureModal(false);
            setSelectedBranch("")
            notifySuccess("Updated Successfully");
            window.location.reload();
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in updating Policy");
      });
  };

  function camelCaseToCapitalCase(str) {
    const words = str.split(/(?=[A-Z])|_/);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const options = [
    { value: "enable", label: "Enable" },
    { value: "disable", label: "Disable" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F8F8FA",
      border: "none",
      boxShadow: state.isFocused ? "none" : provided.boxShadow,
      "&:hover": {
        border: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : provided.backgroundColor,
      "&:hover": {
        backgroundColor: state.isSelected ? "blue" : provided.backgroundColor,
      },
    }),
  };
  
  const onDigitalSignatureModal = () => {
    setDigitalSignatureModal(!digitalSignatureModal);
  };
  
  const userIdPolicySchema = Yup.object().shape({
    userIdLenght: Yup.string()
      .matches(/^[0-9]*$/,  "Only positive numbers are allowed (no special characters or alphabets)")
      .required("User Id Length Required"),

  });
  const idelTimePolicySchema = Yup.object().shape({
    appIdelTimeout: Yup.string()
      .matches(/^[0-9]*$/,  "Only positive numbers are allowed (no special characters or alphabets)")
      .required("Idel Time Policy Required"),

  });
  const passPoliciesSchema = Yup.object().shape({
    numberOfLoginAttempts: Yup.string()
      .matches(/^[0-9]*$/,  "Only positive numbers are allowed (no special characters or alphabets)")
      .required("Number Of Login Attempts Required"),
      passwordExpiration:Yup.string().matches(/^[0-9]*$/,  "Only positive numbers are allowed (no special characters or alphabets)")
      .required("Password Expiration Required"),
      passwordLength:Yup.string().matches(/^[0-9]*$/,  "Only positive numbers are allowed (no special characters or alphabets)")
      .required("Password Length Required"),
      retainPasswordHistory:Yup.string().matches(/^[0-9]*$/,  "Only positive numbers are allowed (no special characters or alphabets)")
      .required("Retain Passwor History required"),
  });
  return (
    <div className="faq-list-main">
      <div className="d-flex gap-2">
      <div className="pr-3 ">
          <img
            src={Back_Arrow}
            alt="backArrow"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
        </div>
      <HeadingMain text={`${camelCaseToCapitalCase(type)} `} />
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div className="faq-table-main">
          <div className="addBrand-main">
            {/* <p className="heading-text">{"Please enter details in English"}</p> */}
            <div className="addBrand-input-form">
              <Formik
                initialValues={{
                  userIdLenght: settings?.setting?.userIdLenght || "",
                  appIdelTimeout: settings?.setting?.appIdelTimeout || "",
                  numberOfLoginAttempts:
                    settings?.setting?.numberOfLoginAttempts || "",
                  passwordComplexity:
                    settings?.setting?.passwordComplexity || "",
                  passwordExpiration:
                    settings?.setting?.passwordExpiration || "",
                  passwordLength: settings?.setting?.passwordLength || "",
                  retainPasswordHistory:
                    settings?.setting?.retainPasswordHistory || "",
                }}
                validationSchema={
                  type === "idelTimePolicy"
                  ? idelTimePolicySchema
                  : type === "userIdPolicy"
                  ? userIdPolicySchema
                  :
                   type === "passwordPolicy"
                  ? passPoliciesSchema
                  : null 

                }
                onSubmit={(values) => {
                  setSetting({ ...settings, ...values });              
                  onDigitalSignatureModal() && updatePolicyData(values);
                  
                }}
                // enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="policies-form p-5">
                      {type === "idelTimePolicy" && (
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="appIdelTimeout"
                          >
                            Idel Time Policy
                          </label>
                          <PrimaryInput
                            type="number"
                            className="form-control"
                            placeholder="Enter"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.appIdelTimeout}
                            name="appIdelTimeout"
                            id="appIdelTimeout"
                          />
                          <CustomErrorMessage
                                name="appIdelTimeout"
                                errors={errors}
                                touched={touched}
                              />
                        </div>
                      )}
                      {type === "userIdPolicy" && (
                        <div className="form-group">
                          <label className="form-label" htmlFor="userIdLenght">
                            User Id Length
                          </label>
                          <PrimaryInput
                            type="text"
                            className="form-control"
                            placeholder="Enter"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userIdLenght}
                            name="userIdLenght"
                            id="userIdLenght"
                          />
                          <CustomErrorMessage
                                name="userIdLenght"
                                errors={errors}
                                touched={touched}
                              />
                        </div>
                      )}
                      {type === "passwordPolicy" && (
                        <>
                          <div className="row">
                            <div className="form-group col-6">
                              <label
                                className="form-label"
                                htmlFor="numberOfLoginAttempts"
                              >
                                Number Of Login Attempts
                              </label>
                              <PrimaryInput
                                type="number"
                                className="form-control"
                                placeholder="Enter"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.numberOfLoginAttempts}
                                name="numberOfLoginAttempts"
                                id="numberOfLoginAttempts"
                              />
                              <CustomErrorMessage
                                name="numberOfLoginAttempts"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className="form-group col-6">
                              <label htmlFor=" passwordComplexity">
                                Password Complexity
                              </label>
                              <Select
                                options={options}
                                value={options.find(
                                  (option) =>
                                    option.value === values.passwordComplexity
                                )}
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "passwordComplexity",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                name="passwordComplexity"
                                id="passwordComplexity"
                                styles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-6">
                              <label
                                className="form-label"
                                htmlFor="passwordExpiration "
                              >
                                Password Expiration
                              </label>
                              <PrimaryInput
                                type="number"
                                className="form-control"
                                placeholder="Enter"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passwordExpiration}
                                name="passwordExpiration"
                                id="passwordExpiration"
                              />
                              <CustomErrorMessage
                                name="passwordExpiration"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className="form-group col-6">
                              <label
                                className="form-label"
                                htmlFor="passwordLength"
                              >
                                Password Length
                              </label>
                              <PrimaryInput
                                type="number"
                                className="form-control"
                                placeholder="Enter "
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passwordLength}
                                name="passwordLength"
                                id="passwordLength"
                              />
                              <CustomErrorMessage
                                name="passwordLength"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                          <div className="form-group col-6">
                            <label
                              className="form-label"
                              htmlFor="retainPasswordHistory"
                            >
                              Retain Passwor History
                            </label>
                            <PrimaryInput
                              type="number"
                              className="form-control"
                              placeholder="Enter "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.retainPasswordHistory}
                              name="retainPasswordHistory"
                              id="retainPasswordHistory"
                            />
                            <CustomErrorMessage
                                name="retainPasswordHistory"
                                errors={errors}
                                touched={touched}
                              />
                          </div>
                        </>
                      )}

                      <div className="save-btn-container mt-5 policy-update_btn">
                        <Button className="theme-btn-main" type="submit">
                          {buttonLoading ? <ButtonLoading /> : "Submit"}
                        </Button>
                      </div>
                    </div>
                    <div className="save-btn-float"></div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
      <Modal
        centered
        show={digitalSignatureModal}
        onHide={onDigitalSignatureModal}
        className="add-department-modal-main"
        size="md"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            E - Signature
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <CustomDigitalSignature
            digitalSignatureHandleSubmit={
              settings.publicId && updatePolicyData
            }
            onDigitalSignatureModal={onDigitalSignatureModal}
            values={settings}
            isLoading={buttonLoading}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Policies;
