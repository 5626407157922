import React, { createContext, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import MainServices from "../services/MainServices";
import useAuth from "../hooks/useAuth";

const AutoLogoutContext = createContext({
  resetTimer: () => {},
});

AutoLogoutProvider.propTypes = {
  children: PropTypes.node,
};

function AutoLogoutProvider({ children }) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { getUserSetting } = MainServices();
  const [idleTime, setIdleTime] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if(isAuthenticated) fetchIdleTime();
  }, [isAuthenticated]);

  const fetchIdleTime = async () => {
    try {
      const details = {};
      const res = await getUserSetting(details, "idelTimePolicy");
      if (res?.success) {
        let timeout = parseFloat(res?.data?.setting?.appIdelTimeout, 10) * 60000;
        setIdleTime(timeout);
      } else {
        setIdleTime(60000);
      }
    } catch (error) {
      console.error("Error fetching idle timeout:", error);
      setIdleTime(60000);
    }
  };

  const onLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  }, [navigate]);

  const startTimer = useCallback(() => {
    if (timeoutId) clearTimeout(timeoutId);
    if (idleTime > 0) {
      const id = setTimeout(onLogout, idleTime);
      setTimeoutId(id);
    }
  }, [idleTime, onLogout, timeoutId]);

  const resetTimer = useCallback(() => {
    if (idleTime > 0) {
      startTimer();
    }
  }, [idleTime, startTimer]);

  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll", "click"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [resetTimer, timeoutId]);

  return (
    <AutoLogoutContext.Provider value={{ resetTimer }}>
      {children}
    </AutoLogoutContext.Provider>
  );
}

export { AutoLogoutContext, AutoLogoutProvider };