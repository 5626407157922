import React, { useEffect, useState } from "react";
import "./App.scss";
import Main from "./Main";
import axios from "axios";
import { HashRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/Auth";
import { ConfigProvider } from "./context/Config";
import { CurrentPageProvider } from "./context/CurrentPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PermissionProvider } from "./context/Permissions";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ThemeProvider } from "./context/Theme";
import { FeatureSettingProvider } from "./context/FeatureSettings";
import { AutoLogoutProvider } from "./context/AutoLogOut";
function App() {
  const [configFetched, setConfigFetched] = useState(false);
  const [config, setConifg] = useState();

  useEffect(() => {
    axios
      .get("config/appConfig.json")
      .then(function (res) {
        var appconfig = res.data;
        setConifg(appconfig);
        setConfigFetched(true);
      })
      .catch(function (res) {
        console.log(res);
        console.log("An error occurred config in appjs");
      });
  }, []);

  return (
    config &&
    configFetched && (
      <PermissionProvider>
        <ConfigProvider>
          <ThemeProvider>
            <AuthProvider>
              <CurrentPageProvider>
                <Router>
                  <DndProvider backend={HTML5Backend}>
                    <FeatureSettingProvider>
                      <AutoLogoutProvider>
                        <Main />
                      </AutoLogoutProvider>
                    </FeatureSettingProvider>
                  </DndProvider>
                  <ToastContainer
                    position="top-right"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </Router>
              </CurrentPageProvider>
            </AuthProvider>
          </ThemeProvider>
        </ConfigProvider>
      </PermissionProvider>
    )
  );
}

export default App;
